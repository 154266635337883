.editorContainer::-webkit-scrollbar {
    width: 0.556rem;
  }

.editorContainer::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
    background: white;
  }
.editorContainer::-webkit-scrollbar-thumb {
    border-radius: 0.926691rem;
    background: #D9D9D9;
  }