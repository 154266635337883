.contentBoxScrollbar::-webkit-scrollbar {
  width: 0;
}

.contentBoxScrollbar::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background: white;
}
.contentBoxScrollbar::-webkit-scrollbar-thumb {
  border-radius: 0.926691rem;
  background: #d9d9d9;
}
