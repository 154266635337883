.editor-wrapper {
  background: #fff;
  height: 100%;
}

.editor-container {
  width: 100%;
  font-size: 2.8rem;
  padding-top: 1rem;
  color: #072b59;
  padding-left: 1rem;
  overflow-y: auto;
  padding-top: 1.2rem;
  height: 15rem;
}

.editor-container::-webkit-scrollbar {
  width: 0.556rem;
}

.editor-container::-webkit-scrollbar-track {
  background: white;
}

.editor-container::-webkit-scrollbar-thumb {
  border-radius: 0.926691rem;
  background: #d9d9d9;
}

.toolbar-grid {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 1fr 1fr;
}

/* button {
  margin-right: 8px;
  font-size: 1rem;
  padding: 10px;
  border: none;
  background: #fff;
  cursor: pointer;
  font-size: 2.24246rem;
} */


.superFancyBlockquote {
  color: #999;
  background: #fff;
  font-family: "Nunito Sans";
  font-style: italic;
  border-left: 2px solid #999;
  padding-left: 10px;
}


.codeBlock {
  font-family: fira-code, monospace;
  font-family: "Nunito Sans";
  background: #ffeff0;
  font-style: italic;
  word-wrap: normal;
  word-wrap: break-word;
  box-decoration-break: slice;
  padding: 0.3rem 2rem;
  border-radius: 0.2rem;
}



.leftAlign {
  text-align: left;
}
.rightAlign {
  text-align: right;
}

.centerAlign {
  text-align: center;
}
.justifyAlign {
  text-align: justify;
}




.editor-container ul {
  list-style-type: disc;
  padding-left: 3rem;
}

.editor-container ol {
  padding-left: 3rem;
  list-style-type: decimal;
}

.jodit-workplace{
  height: 450px !important;
}



.jodit-workplace + .jodit-status-bar:not(:empty) {
  display: none !important;
}

.jodit-toolbar__box:not(:empty):not(:empty),
.jodit-container:not(.jodit_inline) {
  background-color: #fff !important;
  border: none !important;
}




.jodit-toolbar-button_size_small .jodit-icon {
  width: 1rem !important;
  height: 1rem !important;
}



.jodit-ui-checkbox.jodit-ui-checkbox_checked_false.jodit-ui-checkbox_switch_false.jodit-ui-form__nofollow {
  display: none !important;
}



.jodit-placeholder {
  font-size: 1rem !important;
  padding-top: 1rem !important;
}


.jodit-container:not(.jodit_inline) .jodit-wysiwyg ul,
.jodit-container:not(.jodit_inline) .jodit-wysiwyg ol {
  font-size: 2.5rem !important;
  padding-left: 3rem !important;
} 

.jodit-toolbar-button.jodit-toolbar-button_variant_initial[data-ref="disc"] {
  display: none;
}
.jodit-toolbar-button.jodit-toolbar-button_variant_initial[data-ref="default"] {
  display: none;
} 

@media screen and (max-width: 768px) {
  .jodit-container:not(.jodit_inline) .jodit-workplace {
    padding-top: 1rem !important;
  }
}


