.editorContainerModel::-webkit-scrollbar {
  width: 0;
}

.editorContainerModel::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background: white;
}
.editorContainerModel::-webkit-scrollbar-thumb {
  border-radius: 0.926691rem;
  background: #d9d9d9;
}

.reactDatetimePicker {
  border-radius: node !important;
  border: none;
}

/* Sidebar */
.sidebarScroller::-webkit-scrollbar {
  width: 0;
}

.sidebarScroller::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background: white;
}
.sidebarScroller::-webkit-scrollbar-thumb {
  border-radius: 0.926691rem;
  background: #d9d9d9;
}
