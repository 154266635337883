@tailwind base;
@tailwind components;
@tailwind utilities;

.sidebar-list a.active {
  @apply py-2 shadow-md rounded-lg;
}

.sidebar-list a.active span .icon {
  @apply text-sw-100;
}

.custom-bg-image {
  background-image: url("https://images.unsplash.com/photo-1521590832167-7bcbfaa6381f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1000&q=80");
}

::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-thumb {
  background: #d79494;
}

/* default is 11 which is conflicting with other components so keeping it lower here */
.apexcharts-toolbar {
  z-index: 6;
}

.react-datetime-picker__wrapper {
  border: none;
}
